<template>
    <div class="sing-up-index-page">
        <h1 class="sing-up-index-page__title">{{ $lang.pages.registro.register }}</h1>

        <AuthDialogSignUp class="sing-up-index-page__form" page-version />
    </div>
</template>

<script lang="ts" setup>
import type { Api } from '~~/global'

definePageMeta({
    middleware: ['not-auth-required'],
})

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('login-page-data', () => {
    return $fetch<Api.Responses.Pages.Register>(endpoints.pages.register.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    }).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const info = ref(responseData.value)

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: info.value.page.title,
    ogUrl: origin + Route.path,
    ogTitle: info.value.page.title,
    description: info.value.page.description,
    ogDescription: info.value.page.description,
    ogImageAlt: info.value.page.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.sing-up-index-page {
    @apply container;

    &__title {
        @apply my-5 text-center text-4xl lg:my-10;
    }

    &__subtitle {
        @apply mb-3 text-center text-gray-800;
        a {
            @apply cursor-pointer text-site-primary hover:underline;
        }
    }
    &__form {
        @apply mx-auto max-w-[800px];
    }
}
</style>
